@use '../utilities/mixin/font';
@use '../utilities/mixin/icon';
@use '../utilities/mixin/link';

// Buttons
//
// Markup: ../styleguide/markup/form/button.hbs
//
// .btn--size-large - Large variant
//
// Styleguide form.button

.btn-primary {
  border-color: transparent;

  &:focus {
    border-color: transparent;
    background-color: $primary;
  }

  &:hover {
    border-color: transparent;
    color: $primary;
    background-color: $blue-150;
  }

  &:active {
    border-color: transparent;
    color: $white;
    background-color: $blue-600;
  }
}

.btn-info {
  border-color: transparent;

  &:focus {
    border-color: transparent;
    background-color: $info;
  }

  &:hover {
    border-color: transparent;
    color: $info;
    background-color: $blue-150;
  }

  &:active {
    border-color: transparent;
    color: $white;
    background-color: $blue-600;
  }
}

.btn-danger {
  border-color: transparent;

  &:focus {
    border-color: transparent;
    background-color: $danger;
  }

  &:hover {
    border-color: transparent;
    color: $danger;
    background-color: $yellow;
  }

  &:active {
    border-color: transparent;
    color: $red-100;
    background-color: $red-600;
  }
}

.btn-outline-primary {

  &:hover {
    color: $primary;
    border-color: $blue-150;
    background-color: inherit;
  }

  &:active {
    border-color: transparent;
    color: $white;
    background-color: $blue-600;
  }

  &.btn-bg-white {
    border-color: transparent;
    background-color: $white;

    &:hover {
      border-color: transparent;
      color: $white;
      background-color: $gray-900;
    }

    &:active {
      border-color: transparent;
      color: $blue;
      background-color: $blue-150;
    }
  }
}

.btn-outline-secondary {

  &:hover {
    color: $secondary;
    border-color: $blue-150;
    background-color: inherit;
  }

  &:active {
    border-color: transparent;
    color: $white;
    background-color: $blue-600;
  }

  &.btn-bg-white {
    border-color: transparent;
    background-color: $white;

    &:hover {
      border-color: transparent;
      color: $white;
      background-color: $gray-900;
    }

    &:active {
      border-color: transparent;
      color: $blue;
      background-color: $blue-150;
    }
  }
}

.btn-outline-info {

  &:hover {
    color: $info;
    border-color: $blue-150;
    background-color: inherit;
  }

  &:active {
    border-color: transparent;
    color: $white;
    background-color: $blue-600;
  }

  &.btn-bg-white {
    border-color: transparent;
    background-color: $white;

    &:hover {
      border-color: transparent;
      color: $white;
      background-color: $gray-900;
    }

    &:active {
      border-color: transparent;
      color: $blue;
      background-color: $blue-150;
    }
  }
}

.btn {
  @include link.no-line-below;
  @include link.no-box-shadow;
  @include transition(link.$transition);
  @include font.family-frutiger-medium-cond;

  .btn-check:focus + &,
  &:focus,
  &:active:focus {
    box-shadow: $btn-focus-box-shadow;
  }
}

.btn-link {
  @include link.line-below;
  @include link.set-hover-colors;
  @include link.set-active-colors;
  display: inline;
  padding: 0;
  border: 0;

  &:hover,
  &:active {
    @include link.no-line-below;
  }

  &:focus,
  &:active:focus {
    box-shadow: none;
    outline: $focus-outline-width solid var(--focus-outline-color);
  }
}

.btn--search {
  @include icon.mask-image(icon.$search);
  @include icon.mask-size(80%);
  padding: 0 0 0 3.2rem;
  width: 0;
  min-height: 3.2rem;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  &::before {
    @include transition(top 0.2s);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: currentColor;
  }
}

.btn--size-large {
  font-size: 1.375rem;
  line-height: 1.27;
  letter-spacing: 0.045em;
  padding: 0.363em 1.09em;
}
