@use '../configuration/variables';
@use '../utilities/mixin/font';
@use '../utilities/mixin/link';
@use '../utilities/mixin/triangle';
@use 'sass:math';

.home-page :where(h2, .h2) {
  color: variables.$primary;
  font-size: 2em;
}

.home-page__start {
  padding-top: 1.5rem;

  &::before {
    @include triangle.top-right;
    background-color: variables.$blue-150;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 110rem;
    z-index: #{variables.$zindex-triangles + 1};
    pointer-events: none;
  }

  .frame {
    order: 2;
  }

  @include media-breakpoint-up('md') {
    margin-left: calc(var(--bs-gutter-x) * -0.5);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    padding-top: 7rem;

    &::before {
      width: 70vw;
    }

    .frame {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);

      &:nth-of-type(2n + 1) {
        min-height: 23rem;
        margin-right: 50%;
      }

      &:nth-of-type(2n) {
        margin-left: 25%;
        margin-right: 25%;
      }
    }

    @include media-breakpoint-up('lg') {

      .frame {

        &:nth-of-type(2n + 1) {
          margin-right: math.div(700%, 12);
        }

        &:nth-of-type(2n) {
          margin-left: math.div(500%, 12);
          margin-right: math.div(200%, 12);
        }
      }
    }
  }
}

.home-page__language-link {
  display: none;

  @include media-breakpoint-up('md') {
    display: flex;
    justify-content: end;
    position: absolute;
    top: -5.5rem;
    right: 5%;
    width: 19rem;
    z-index: variables.$zindex-content;
  }

  @include media-breakpoint-up('lg') {
    width: 24rem;
    right: 10%;
  }
}

.home-page__end {
  overflow-x: hidden;

  .frame-in-diagonal-bar {
    position: relative;

    > .zindex-content {
      margin-top:  20rem;
    }
  }

  @include media-breakpoint-up('md') {

    .frame-in-diagonal-bar {
      position: static;

      > .zindex-content {
        margin-top: 0;
        margin-bottom:  12rem;
      }

      + .frame-in-diagonal-bar {

        > .zindex-content {
          margin-top: -12rem;
        }
      }

      &:not(:where(.frame-in-diagonal-bar + .frame-in-diagonal-bar)) {
        padding-top: 15rem;
      }
    }

    .diagonal-bar--before {
      margin-top: -9rem;
    }

    .diagonal-bar--after {
      margin-top: -5rem;
    }
  }

  @include media-breakpoint-up('lg') {

    .frame-in-diagonal-bar {

      > .zindex-content {
        margin-bottom: calc(#{variables.$reciprocal-altitude * 50%} + 5rem);
      }

      + .frame-in-diagonal-bar > .zindex-content {
        margin-top: calc(#{variables.$reciprocal-altitude * -50%} - 5rem);
      }

      &.frame-type-text {

        > .zindex-content {
          margin-bottom: calc(#{variables.$reciprocal-altitude * 50%} + 2rem);
        }

        + .frame-in-diagonal-bar > .zindex-content {
          margin-top: calc(#{variables.$reciprocal-altitude * -50%} - 2rem);
        }
      }
    }
  }
}

.home-page__triangles {
  display: none;

  &::before,
  &::after {
    @include triangle.equilateral;
    content: '';
    position: absolute;
    z-index: #{variables.$zindex-triangles + 1};
    pointer-events: none;
  }

  &:where(.frame-in-diagonal-bar + .frame-in-diagonal-bar *) {
    display: block;
  }

  @include media-breakpoint-up('md') {
    display: block;

    &:where(.frame-in-diagonal-bar + .frame-in-diagonal-bar *) {
      display: none;
    }
  }
}

.home-page__triangles--blue {
  display: block;

  &:where(.frame-in-diagonal-bar + .frame-in-diagonal-bar *) {
    display: none;
  }
}

.home-page__triangles--blue::before {
  background-color: variables.$blue;
  height: 8rem;
  margin-left: 9rem;
  margin-top: -20rem;
}

.home-page__triangles--blue::after {
  background-color: variables.$blue-300;
  height: 16rem;
  margin-left: -3rem;
  margin-top: -17rem;
}

.home-page__triangles--rose::before {
  background-color: variables.$rose;
  height: 16rem;
  margin-left: calc(40vw - #{variables.$equilateral-triangle-altitude * 8rem});
  margin-top: -20rem;
}

.home-page__triangles--rose::after {
  background-color: variables.$rose;
  height: 16rem;
  margin-left: 40vw;
  margin-top: -20rem;
}

.home-page__triangles--red::before {
  background-color: variables.$hrk;
  height: 8rem;
  margin-left: 40vw;
  margin-top: -16rem;
}

@include media-breakpoint-up('md') {

  .home-page__triangles--blue::before {
    margin-left: -3rem;
    margin-top: -14rem;
  }

  .home-page__triangles--blue::after {
    margin-left: -14.5rem;
    margin-top: -9.5rem;
  }

  .home-page__triangles--rose::before {
    margin-left: calc(76vw - #{variables.$equilateral-triangle-altitude * 8rem});
    margin-top: -11rem;
  }

  .home-page__triangles--rose::after {
    margin-left: 76vw;
    margin-top: -11rem;
  }

  .home-page__triangles--red::before {
    margin-left: 76vw;
    margin-top: -7rem;
  }
}

@include media-breakpoint-up('lg') {

  .home-page__triangles--rose::before {
    margin-left: calc(50vw - #{variables.$equilateral-triangle-altitude * 8rem});
  }

  .home-page__triangles--rose::after {
    margin-left: 50vw;
  }

  .home-page__triangles--red::before {
    margin-left: 50vw;
  }
}

.home-page__start-inner {
  display: flex;
  position: relative;
  flex-direction: column;
}

.home-page__speech-bubble {
  --link-icon-color: currentColor;
  color: variables.$white;
  background-color: rgba(variables.$hrk, 0.8);
  position: relative;
  z-index: variables.$zindex-content;
  margin: 0 auto 5rem;
  max-width: 30rem;
  order: 1;

  @include media-breakpoint-up('md') {
    @include font.family-frutiger-medium;
    position: absolute;
    top: -2rem;
    right: 5%;
    width: 19rem;
    margin: 0;
    max-width: none;
  }

  @include media-breakpoint-up('lg') {
    width: 24rem;
    right: 10%;
  }

  &::after {
    @include triangle.top-right;
    content: '';
    position: absolute;
    top: 100%;
    background-color: rgba(variables.$hrk, 0.8);
    width: 3rem;
    right: 3rem;
    margin-left: -1.5rem;
  }

  a {
    @include link.no-line-below;
    --link-hover-color: #{variables.$hrk};
    --link-hover-background-color: #{variables.$white};
    --link-active-color: #{variables.$hrk};
    --link-active-background-color: #{variables.$white};
    --link-icon-color: #{$white};
  }
}

.home-page__speech-bubble-inner {
  padding: 1.5rem 2rem;

  @include media-breakpoint-up('md') {
    max-height: 27rem;
    overflow: auto;
  }
}

.home-page__item-headline {
  @include font.family-frutiger-medium-cond;
  font-size: 1.25rem;
  color: currentColor;
  margin: 0 0 1rem;

  @include media-breakpoint-up('md') {
    font-size: 1.5rem;
  }
}

.home-page__item-date {
  margin-top: 0.5rem;
  font-size: font.$small-font-size;
}

.home-page__news-list-footer {
  margin-top: 1rem;
}

.home-page__news-list-link {
  font-size: font.$medium-small-font-size;
}

.home-page__item-link {
  display: block;
}
